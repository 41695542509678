import {createClient} from '@sanity/client';

import {apiVersion, dataset, projectId, studioUrl} from './api';

export const client = createClient({
	projectId,
	dataset,
	apiVersion,
	useCdn: false,
	perspective: 'published',
	stega: {
		studioUrl,
		filter: (props) => {
			if (props.sourcePath.at(-1) === 'title') {
				return true;
			}

			return props.filterDefault(props);
		},
	},
});

export const clientWritable = createClient({
	projectId,
	dataset,
	apiVersion,
	useCdn: false,
	perspective: 'published',
	token: process.env.SANITY_FORM_API_TOKEN,
	stega: {
		studioUrl,
		filter: (props) => {
			if (props.sourcePath.at(-1) === 'title') {
				return true;
			}

			return props.filterDefault(props);
		},
	},
});
