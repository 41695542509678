import {groq} from 'next-sanity';

import {LINK_FRAGMENT, type LinkFragment} from '@/modules/foundation/sanity/link/query';
import type {SanityArray} from '@/sanity/lib/types';

import {DOCUMENT_TYPE_SITE_SETTINGS} from './constants';
import type {SiteSettingsSchema} from './schema';

const DROPDOWN_FRAGMENT = groq`
	...,
	links[] {
		${LINK_FRAGMENT},
	}
`;

type LinkWithTitleFragment = LinkFragment & {
	title?: string;
};

export type NavLinkFragment = LinkWithTitleFragment & {
	featured?: boolean;
};

export type DropdownFragment = {
	_type: 'dropdown';
	title?: string;
	links?: SanityArray<NavLinkFragment>;
};

export const SITE_SETTINGS_FRAGMENT = groq`
	...,
	navigation[] {
		_type == "link" => {
			${LINK_FRAGMENT},
		},
		_type == "dropdown" => {
			${DROPDOWN_FRAGMENT}
		}
	},
	footerLinks[] {
		${LINK_FRAGMENT}
	}
`;

type NavigationFragment = SanityArray<NavLinkFragment | DropdownFragment>;

export type SiteSettingsFragment = Omit<SiteSettingsSchema, 'navigation' | 'footerLinks'> & {
	navigation?: NavigationFragment;
	footerLinks?: SanityArray<LinkWithTitleFragment>;
};

export const SITE_SETTINGS_QUERY = groq`
	*[_type == "${DOCUMENT_TYPE_SITE_SETTINGS}"][0] {
		${SITE_SETTINGS_FRAGMENT}
	}
`;

export type SiteSettingsQueryResult = SiteSettingsFragment | null;
