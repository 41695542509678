import {type QueryParams} from '@sanity/react-loader';
import {useLiveQuery} from 'next-sanity/preview';

/**
 * Exports to be used in client-only or components that render both server and client
 */
export const useQuery = <QueryResponseResult = unknown>(
	initial: QueryResponseResult | null,
	query: string,
	params?: QueryParams,
) => {
	const [data] = useLiveQuery<QueryResponseResult | null>(initial, query, params);

	return {
		data,
	};
};
