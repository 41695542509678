import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';
import {twMerge} from 'tailwind-merge';

const button = cva(
	['no-underline', 'inline-flex', 'items-center', 'justify-center', 'gap-2', 'shrink-0'],
	{
		variants: {
			intent: {
				primary: ['bg-primary', 'text-primary-light'],
				secondary: ['bg-primary-light', 'text-primary'],
			},
			size: {
				small: ['px-2', 'py-1', 'text-base'],
				medium: ['px-4', 'py-2', 'text-lg'],
				large: ['px-6', 'py-3', 'text-xl'],
			},
		},
		defaultVariants: {
			intent: 'primary',
			size: 'medium',
		},
	},
);

export type ButtonProps = VariantProps<typeof button> &
	ComponentPropsWithoutRef<'button'> & {
		/**
		 * Change the default rendered element for the one passed as a child, merging their props and behavior.
		 * @default false
		 */
		asChild?: boolean;
	};

/**
 * Button used for interaction
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			intent = 'primary',
			size = 'medium',
			type = 'button',
			className,
			asChild,
			...rest
		},
		ref,
	) => {
		const Component = asChild ? Slot : 'button';

		return (
			<Component
				ref={ref}
				type={type}
				className={twMerge(button({intent, size, className}))}
				{...rest}
			>
				{children}
			</Component>
		);
	},
);

Button.displayName = 'Button';
