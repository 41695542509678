'use client';

import type {FC} from 'react';
import {useRef, useState} from 'react';
import clsx from 'clsx';

import {Dropdown} from './Dropdown';
import type {NavigationType} from './Navbar';
import {NavLink} from './NavLink';

type Props = {
	navigation: NavigationType;
};

export const DesktopNav: FC<Props> = ({navigation}) => {
	const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);

	const navRef = useRef<HTMLDivElement>(null);

	return (
		<nav ref={navRef} className={clsx('z-50', 'hidden', 'md:block')}>
			<ul className={clsx('flex', 'gap-10', 'items-center')}>
				{navigation.map((item) => {
					if (item.type === 'dropdown' && item.links && item.links.length > 0) {
						return (
							<Dropdown
								dropdown={item}
								key={item._key}
								id={item._key}
								navRef={navRef}
								activeDropdownId={activeDropdownId}
								setActiveDropdownId={setActiveDropdownId}
							/>
						);
					}

					if (item.type === 'navlink') {
						return (
							<li key={item._key}>
								<NavLink link={item} />
							</li>
						);
					}
				})}
			</ul>
		</nav>
	);
};
