import type {FC} from 'react';
import clsx from 'clsx';

interface Props {
	isOpen: boolean;
}

export const Hamburger: FC<Props> = ({isOpen}) => {
	return (
		<div aria-hidden="true">
			<div
				className={clsx(
					'h-[3px]',
					'w-6',
					'bg-gray-700',
					'rounded-full',
					'transition-transform',
					isOpen ? 'translate-x-0 translate-y-[9px] rotate-45' : '',
				)}
			/>
			<div
				className={clsx(
					'h-[3px]',
					'w-6',
					'bg-gray-700',
					'rounded-full',
					'my-[6px]',
					'transition-transform',
					isOpen ? 'scale-0 opacity-0' : '',
				)}
			/>
			<div
				className={clsx(
					'h-[3px]',
					'w-6',
					'bg-gray-700',
					'rounded-full',
					'transition-transform',
					isOpen ? 'translate-x-0 translate-y-[-9px] -rotate-45' : '',
				)}
			/>
		</div>
	);
};
