export function formatPhoneNumber(phoneNumber: string): string {
	// Check if the phone number starts with an international prefix (e.g., '+47')
	const hasInternationalPrefix = phoneNumber.startsWith('+');

	// Remove all non-digit characters from the phone number
	const digitsOnly = phoneNumber.replace(/\D/g, '');

	// Group every two digits
	const formatted = digitsOnly.replace(/(\d{2})(?=\d)/g, '$1 ');

	// Add back the international prefix if it was present
	return hasInternationalPrefix ? `+${formatted}` : formatted;
}

export function createPhoneLink(phoneNumber: string): string {
	// Remove all spaces and non-digit characters, except the plus sign at the beginning
	const cleanedNumber = phoneNumber.replace(/[^\d+]|(?<=\+)\s/g, '');

	// Return the formatted 'tel' link
	return `tel:${cleanedNumber}`;
}

export function formatDate(date: string) {
	const newDate = new Date(date);
	return newDate.toLocaleDateString('nb-NO', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
}
