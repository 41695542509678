import type {FC, ReactNode} from 'react';
import clsx from 'clsx';

import {Container} from '@/modules/foundation/components/container';
import {Link} from '@/modules/foundation/components/link';
import {Logo} from '@/modules/foundation/components/logo';
import {Heading} from '@/modules/foundation/components/typography/heading';
import {Paragraph} from '@/modules/foundation/components/typography/paragraph';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';
import type {KeyedArray} from '@/modules/foundation/shared/types';

type Address = {
	streetName?: string;
	postalCode?: string;
	city?: string;
};

type Organization = {
	organizationNumber?: string;
	address?: Address;
	phoneNumber?: string;
	email?: string;
};

type FooterLink = {
	href?: string;
	title?: string;
	targetBlank?: boolean;
};

export type FooterProps = {
	socialMediaLinks?: KeyedArray<FooterLink>;
	organization?: Organization;
	links?: KeyedArray<FooterLink>;
};

export const Footer: FC<FooterProps> = ({links, organization, socialMediaLinks}) => {
	const {address, phoneNumber, email, organizationNumber} = organization || {};

	return (
		<Container as="footer" className={clsx('py-12', 'bg-gray-100')}>
			<Logo height={32} aria-hidden="true" className={clsx('mb-6')} />
			<div className={clsx('grid', 'grid-cols-1', 'lg:grid-cols-2', 'gap-6')}>
				<div className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-6')}>
					<div className={clsx('flex', 'flex-col', 'gap-3', 'sm:gap-6')}>
						{address && (
							<div>
								<FooterTitle>Besøksadresse:</FooterTitle>
								<Paragraph size="small">
									<span>{address.streetName}</span>,{' '}
									<span>{address.postalCode}</span> <span>{address.city}</span>
								</Paragraph>
							</div>
						)}

						{organizationNumber && (
							<div>
								<FooterTitle>Organisasjonsnummer:</FooterTitle>
								<Paragraph size="small">{organizationNumber}</Paragraph>
							</div>
						)}
					</div>

					<div className={clsx('flex', 'flex-col', 'gap-3', 'sm:gap-6')}>
						{phoneNumber && (
							<div>
								<FooterTitle>Telefon:</FooterTitle>
								<Link href={createPhoneLink(phoneNumber)}>
									{formatPhoneNumber(phoneNumber)}
								</Link>
							</div>
						)}

						{email && (
							<div>
								<FooterTitle>E-post:</FooterTitle>
								<Link href={`mailto:${email.replaceAll(' ', '')}`}>{email}</Link>
							</div>
						)}
					</div>
				</div>

				<div className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-6')}>
					{links?.length && (
						<div>
							<FooterTitle>Lenker:</FooterTitle>
							<ul>
								{links.map((link) => {
									return (
										<li key={link._key}>
											<Link href={link.href} targetBlank={link.targetBlank}>
												{link.title}
											</Link>
										</li>
									);
								})}
							</ul>
						</div>
					)}

					{socialMediaLinks?.length && (
						<div>
							<FooterTitle>Følg oss:</FooterTitle>
							<ul>
								{socialMediaLinks.map((link) => (
									<li key={link._key}>
										<Link href={link.href} targetBlank={link.targetBlank}>
											{link.title}
										</Link>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</Container>
	);
};

const FooterTitle = ({children}: {children: ReactNode}) => (
	<Heading level={2} size="large" spacing>
		{children}
	</Heading>
);
